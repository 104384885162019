import React from "react"
import {makeStyles} from "@mui/styles";
import cx from "classnames";
import {graphql, useStaticQuery} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";

const useStyles = makeStyles((theme) => ({
    blockContainer: {
        position: 'absolute',
        height: 'calc(100dvh - 52px)',
        display: 'flex',
        opacity: 1,
        transition: 'opacity 1200ms linear',
        flexDirection: 'column',
        width: '100vw',
        overflow: 'hidden',

        [theme.breakpoints.up('mobile')]: {
            height: 'calc(100dvh - 100px)',
        },
        [theme.breakpoints.up('laptop')]: {
            width: 'calc(100vw - 100px)',
        },
    },
    contactInfoContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',

        [theme.breakpoints.down('laptop')]: {
            flexDirection: 'column',
        },
    },
    hideBlock: {
        opacity: 0,
        transition: 'opacity 600ms linear',
    },
    contactContainer: {
        flex: 1,
        borderBottom: `1px solid ${theme.palette.grey.main}`,
        borderTop: `1px solid ${theme.palette.grey.main}`,
        width: '100vw',
        color: theme.palette.grey.second,
        textTransform: "uppercase",
        fontFamily: 'Oswald',
        fontSize: 46,
        fontWeight: 500,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 40,
        flexDirection: 'column',
        transition: 'transform 1000ms linear',
        transform: 'translateX(0%) translateY(0%) scale(1)',

        [theme.breakpoints.down('mobile')]: {
            fontSize: 28,
        },

        [theme.breakpoints.up('laptop')]: {
            borderLeft: `1px solid ${theme.palette.grey.main}`,
        },

    },
    noBorders: {
        borderBottom: 'none',
        borderTop: 'none',
        borderLeft: 'none',

        [theme.breakpoints.up('laptop')]: {
            borderTop: `1px solid ${theme.palette.grey.main}`,
            borderBottom: `1px solid ${theme.palette.grey.main}`,
        },
    },
    mobileNumbersContainer: {
        fontSize: 18,
        fontFamily: 'Montserrat',
        fontWeight: 700,
        display: 'flex',
        flexDirection: 'column',
        gap: 25,

        [theme.breakpoints.up('mobile')]: {
            gap: 40,
        },
    },
    followUsContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 32,

        [theme.breakpoints.down('mobile')]: {
            gap: 12,
        },
    },
    image: {
        height: 48,
        width: 48,
        transition: 'background-color 300ms linear',
        backgroundColor: theme.palette.grey.second,

        [theme.breakpoints.up('tablet')]: {
            height: 88,
            width: 88,
        },

        '&:hover': {
            backgroundColor: theme.palette.orange.main,
            transition: 'background-color 300ms linear',
        },

        '& img': {
            filter: 'brightness(0%)',
            transition: 'filter 300ms linear',

            '&:hover': {
                filter: 'brightness(100%)',
                transition: 'filter 300ms linear',
            },
        },
    },
    footer: {
        height: 120,
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.grey.second,
        fontFamily: 'Montserrat',
        fontSize: 16,

        [theme.breakpoints.down('mobile')]: {
            height: 60,
        },
    },
}));

const SeventhBlock = ({isVisible, blockRef}) => {
    const classes = useStyles();
    const {strapiContactUs} = useStaticQuery(graphql`
      query {
        strapiContactUs {
          Behance_url
          FB_url
          Twitter_url
          Linkedin_url
          First_phone_number
          Second_phone_number
          Copyright
        }
      }
    `)

    return (
        <div ref={blockRef} className={cx(classes.blockContainer, {
            [classes.hideBlock]: !isVisible,
        })}
             style={{zIndex: isVisible ? 1 : 0}}
        >
            <div className={classes.contactInfoContainer}>
                <div className={cx(classes.contactContainer, classes.noBorders)}>
                    {'Contact Us'}
                    <div className={classes.mobileNumbersContainer}>
                        <div>{strapiContactUs.First_phone_number}</div>
                        <div>{strapiContactUs.Second_phone_number}</div>
                    </div>
                </div>
                <div className={classes.contactContainer}>
                    {'Follow Us'}
                    <div className={classes.followUsContainer}>
                        <a href={strapiContactUs.FB_url} target="_blank">
                            <StaticImage src={"../../../../assets/follow_us/FB.webp"} alt="FB" className={classes.image}/>
                        </a>
                        <a href={strapiContactUs.Linkedin_url} target="_blank">
                            <StaticImage src={"../../../../assets/follow_us/Linkedin.webp"} alt="Linkedin" className={classes.image}/>
                        </a>
                        <a href={strapiContactUs.Behance_url} target="_blank">
                            <StaticImage src={"../../../../assets/follow_us/Behance.webp"} alt="Behance" className={classes.image}/>
                        </a>
                        <a href={strapiContactUs.Twitter_url} target="_blank">
                            <StaticImage src={"../../../../assets/follow_us/Twitter.webp"} alt="Twitter" className={classes.image}/>
                        </a>
                    </div>
                </div>
            </div>
            <div className={classes.footer}>{strapiContactUs.Copyright}</div>
        </div>
    )
}

export default SeventhBlock